<!--
 * @Author: 智客云网络科技
 * @Date: 2021-05-20 11:23:11
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-17 15:43:35
 * @Description: 创建 修改 快件
 * @FilePath: \src\views\App\ExpressCargo\NUExpressCargo.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="快件单号：" prop="name" style="width: 45%">
            <el-input
              size="small"
              :disabled="express_cargo_id_status"
              v-model="m.express_id"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="快件状态：" style="width: 45%">
            <el-select size="small" v-model="m.status">
              <el-option label="已入库" :value="150010"></el-option>
              <el-option label="分拣中" :value="150011"></el-option>
              <el-option label="在库" :value="150012"></el-option>
              <el-option label="已拣出" :value="150013"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="仓库："
            v-if="m.status >= 150012"
            style="width: 45%"
          >
            <el-select size="small" v-model="m.kwh_id">
              <el-option
                v-for="item in warehouseList"
                :key="item.kwh_id"
                :label="item.name"
                :value="item.kwh_id"
              >
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content="刷新仓库列表"
              placement="top-start"
            >
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="_GetWarehouseList()"
              ></el-button>
            </el-tooltip>
          </el-form-item>

          <el-form-item
            label="库位："
            v-if="m.status >= 150012"
            style="width: 45%"
          >
            <el-select size="small" filterable v-model="m.kst_id">
              <el-option
                v-for="item in storageList"
                :key="item.kst_id"
                :label="item.role"
                :value="item.kst_id"
              >
                <span style="float: left">{{ item.role }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.kcu_name
                }}</span>
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content="刷新库位列表"
              placement="top-start"
            >
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="_GetStorageList()"
              ></el-button>
            </el-tooltip>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item
              label="描述："
              prop="notes"
              style="width: 90%; display: block"
            >
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateExpressCargo()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateExpressCargo()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import {
  createExpressCargo,
  updateExpressCargo,
  getExpressCargoInfo,
  getWarehouseList,
  getStorageList,
} from "./api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        express_id: "", //快件单号
        status: 150010, //快件状态
        add_wh_time: "", //入库时间
        to_shelves_time: "", //上架时间
        picking_time: "", //拣出时间
        kwh_id: "", //仓库ID
        kst_id: "", //库位ID
        details: "", //物品明细
        notes: "", //描述
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      warehouseList: [], //仓库列表
      storageList: [], //库位列表

      //禁用快递单号输入
      express_cargo_id_status: false, //快递单号
    };
  },
  watch: {
    //监听快件状态变动刷新仓库数据
    "m.status": {
      handler(val) {
        if (val >= 150012) {
          this._GetWarehouseList();
        }
      },
    },
    "m.kwh_id": {
      handler(val) {
        if (val !== null && val !== "") {
          this._GetStorageList();
        }
      },
    },
  },
  methods: {
    // 打开
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.$nextTick(() => {
        if (id === 0) {
          //新建
          this.title = "添加新快件";
          this.loading = false;
        } else {
          //修改
          this._GetExpressCargoInfo(id);
          this.title = "修改快件信息";
        }
      });
    },

    //添加新快件
    _CreateExpressCargo() {
      var m = this.m;
      createExpressCargo({
        express_id: m.express_id, //快件单号
        status: m.status, //快件状态
        kwh_id: m.kwh_id, //仓库ID
        kst_id: m.kst_id, //库位ID
        kcu_id: this.kcu_id, //客户ID
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改快件
    _UpdateExpressCargo() {
      var m = this.m;
      updateExpressCargo({
        kec_id: this.id, //快件ID
        status: m.status, //快件状态
        kwh_id: m.kwh_id, //仓库ID
        kst_id: m.kst_id, //库位ID
        kcu_id: this.kcu_id, //客户ID
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取快件信息
    _GetExpressCargoInfo(kec_id) {
      getExpressCargoInfo({ kec_id })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.express_id = d.express_id; //快件单号
          m.status = d.status; //快件状态
          m.kwh_id = d.kwh_id; //仓库ID
          m.kst_id = d.kst_id; //库位ID
          m.notes = d.notes; //描述

          //禁用快递单号输入框 使其变为只读状态
          this.express_cargo_id_status = true;

          this.loading = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取仓库列表
    _GetWarehouseList() {
      getWarehouseList({
        kor_id: this.$parent.p.kor_id,
        pageNo: 1, //当前页
        pageSize: 999, //分页行数
      })
        .then((res) => {
          this.kwh_id = "";
          this.warehouseList = res.data;
          //   this.m.kwh_id = res.data[0]["kwh_id"];
        })
        .catch((err) => {
          if (err.code === 130003) {
            this.$utils.msg(err.err_msg);
          } else {
            this.$utils.error(err.err_msg);
          }
          this.warehouseList = [];
        });
    },

    //获取库位列表
    _GetStorageList() {
      getStorageList({
        kwh_id: this.m.kwh_id,
      })
        .then((res) => {
          this.kst_id = "";
          this.storageList = res.data;
        })
        .catch((err) => {
          if (err.code === 140016) {
            this.$utils.msg(err.err_msg);
          } else {
            this.$utils.error(err.err_msg);
          }
          this.storageList = [];
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
  created() {},
};
</script>
