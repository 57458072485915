/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:02:25
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\App\ExpressCargo\api.js
 */

import http from "@/api";

/**************************** 快递包裹操作 ****************************/

// 添加快件
export const createExpressCargo = (params) =>
  http.post("/v1/ExpressCargo/CreateExpressCargo", params);

// 删除快件
export const delExpressCargo = (params) =>
  http.delete("/v1/ExpressCargo/DelExpressCargo", params);

// 修改快件
export const updateExpressCargo = (params) =>
  http.put("/v1/ExpressCargo/UpdateExpressCargo", params);

// 根据ID查询快件信息
export const getExpressCargoInfo = (params) =>
  http.get("/v1/ExpressCargo/GetExpressCargoInfo", params);

// 获取快件表格
export const getExpressCargoTable = (params) =>
  http.get("/v1/ExpressCargo/GetExpressCargoTable", params);

/**************************** 仓库操作 ****************************/

//获取仓库列表
export const getWarehouseList = (params) =>
  http.get("/v1/Warehouse/GetWarehouseList", params);

/**************************** 库位操作 ****************************/

//获取库位列表
export const getStorageList = (params) =>
  http.get("/v1/Storage/GetStorageList", params);
