<!--
 * @Author: 智客云网络科技
 * @Date: 2021-11-04 00:15:17
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 09:20:15
 * @Description: 快递包裹管理
 * @FilePath: \src\views\App\ExpressCargo\ExpressCargoManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 检索 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>组织筛选：</label>
          <organization-picker v-model="p.kor_id"></organization-picker>
        </div>

        <div class="table-search-item" style="width: 35em">
          <label>入库时间：</label>
          <el-date-picker
            v-model="p.time"
            type="datetimerange"
            :picker-options="pickerOptions"
            :default-time="defaultTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            align="right"
          >
          </el-date-picker>
        </div>

        <div class="table-search-item">
          <label>快件状态：</label>
          <el-select size="small" v-model="p.status">
            <el-option label="所有" :value="150000"></el-option>
            <el-option label="已入库" :value="150010"></el-option>
            <el-option label="分拣中" :value="150011"></el-option>
            <el-option label="在库" :value="150012"></el-option>
            <el-option label="已拣出" :value="150013"></el-option>
          </el-select>
        </div>

        <div class="table-search-item">
          <label>客户筛选：</label>
          <el-input
            size="small"
            v-model="p.kcu_name"
            @keyup.enter.native="
              p.pageNo = 1;
              _Load();
            "
            suffix-icon="xxxx"
          ></el-input>
        </div>

        <div class="table-search-item">
          <label>快递单号：</label>
          <el-input
            size="small"
            v-model="p.kec_express_id"
            @keyup.enter.native="
              p.pageNo = 1;
              _Load();
            "
            suffix-icon="xxxx"
          ></el-input>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenCreateExpressCargoModel()"
      >
        录入快递包裹
      </el-button>
      <el-button
        size="small"
        type="success"
        icon="el-icon-document-copy"
        @click="copyExpressIdList()"
      >
        复制单号列表
      </el-button>
      <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_Load()"
        >刷新</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      ref="table"
      size="mini"
      stripe
      border
      row-key="id"
    >
      <!-- 多选 -->
      <el-table-column type="selection" align="center" width="55px">
      </el-table-column>

      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 快递单号 -->
      <el-table-column
        label="快递单号"
        prop="express_id"
        align="center"
        width="160px"
      ></el-table-column>

      <!-- 快件状态 -->
      <el-table-column label="快件状态" align="center" width="115px">
        <template slot-scope="s">
          <el-tag size="small" effect="plain" v-if="s.row.status == 150010">
            已入库
          </el-tag>

          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.status == 150011"
          >
            分拣中
          </el-tag>

          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-else-if="s.row.status == 150012"
          >
            在库
          </el-tag>

          <el-tag
            size="small"
            effect="plain"
            type="warning"
            v-else-if="s.row.status == 150013"
          >
            已拣出
          </el-tag>

          <el-tag
            size="small"
            effect="plain"
            type="info"
            v-else-if="s.row.status == 150014"
          >
            已打包
          </el-tag>

          <el-tag
            size="small"
            effect="plain"
            type="info"
            v-else-if="s.row.status == 150015"
          >
            待发货
          </el-tag>

          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.status == 150016"
          >
            已发货
          </el-tag>

          <el-tag size="small" effect="plain" type="info" v-else>未知</el-tag>
        </template>
      </el-table-column>

      <!-- 仓库 -->
      <el-table-column
        label="仓库"
        prop="kwh_name"
        align="center"
        width="180px"
      ></el-table-column>

      <!-- 存储位置 -->
      <el-table-column
        label="存储位置"
        prop="role"
        align="center"
        width="130px"
      ></el-table-column>

      <!-- 客户姓名 -->
      <el-table-column
        label="客户姓名"
        prop="kcu_name"
        align="center"
        width="150px"
      ></el-table-column>

      <!-- 时间 -->
      <el-table-column label="时间" align="left" width="190px">
        <template slot-scope="s">
          <span v-if="s.row.add_wh_time !== null"
            >入库时间:{{ s.row.add_wh_time }}<br
          /></span>
          <span v-if="s.row.to_shelves_time !== null"
            >上架时间:{{ s.row.to_shelves_time }}<br
          /></span>
          <span v-if="s.row.picking_time !== null"
            >拣出时间:{{ s.row.picking_time }}<br
          /></span>
          <span v-if="s.row.pack_time !== null"
            >打包时间:{{ s.row.pack_time }}<br
          /></span>
        </template>
      </el-table-column>

      <!-- 备注 -->
      <el-table-column label="备注">
        <template slot-scope="s"
          ><span>{{ s.row.notes }}</span></template
        >
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateExpressCargoModel(s.row.kec_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelExpressCargo(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[50, 100, 150, 300]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-express-cargo ref="nu-express-cargo"></nu-express-cargo>
  </div>
</template>

<script>
import NuExpressCargo from "./NUExpressCargo";
import { getExpressCargoTable, delExpressCargo } from "./api"; //页面专有接口
import OrganizationPicker from "@/components/organizationPicker";
export default {
  name: "ExpressCargoManage",
  components: {
    NuExpressCargo,
    OrganizationPicker,
  },
  data() {
    return {
      p: {
        kor_id: "", //组织ID
        pageNo: 1, //当前页
        pageSize: 50, //分页行数
        dataCount: 0, //数据总行数
        kcu_name: "", //客户姓名
        time: "", //时间筛选
        status: 150000, //快件状态
        kec_express_id: "", //快递单号筛选
      },
      TableData: [], // 数据集合
      show_customer_select: false, //控制客户选择模块是否显示
      defaultTime: ["00:00:00", "23:59:59"], //选中日期后的默认具体时刻
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                  24 * 60 * 60 * 1000 -
                  1
              );
              const start = new Date(
                new Date(new Date().toLocaleDateString()).getTime()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() - 1
              );
              const start = new Date(
                new Date(new Date().toLocaleDateString()).getTime() -
                  24 * 60 * 60 * 1000
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                  24 * 60 * 60 * 1000 -
                  1
              );
              const start = new Date(
                new Date(new Date().toLocaleDateString()).getTime() -
                  24 * 60 * 60 * 1000 * 7
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                  24 * 60 * 60 * 1000 -
                  1
              );
              const start = new Date(
                new Date(new Date().toLocaleDateString()).getTime() -
                  24 * 60 * 60 * 1000 * 30
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                  24 * 60 * 60 * 1000 -
                  1
              );
              const start = new Date(
                new Date(new Date().toLocaleDateString()).getTime() -
                  24 * 60 * 60 * 1000 * 90
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                  24 * 60 * 60 * 1000 -
                  1
              );
              const start = new Date(
                new Date(new Date().toLocaleDateString()).getTime() -
                  24 * 60 * 60 * 1000 * 180
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                  24 * 60 * 60 * 1000 -
                  1
              );
              const start = new Date(
                new Date(new Date().toLocaleDateString()).getTime() -
                  24 * 60 * 60 * 1000 * 365
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      }, //当前时间日期选择器特有的选项参考下表
    };
  },
  watch: {
    //监听组织ID变动加载数据
    "p.kor_id": {
      handler() {
        this._Load();
      },
    },
  },
  methods: {
    //加载表格数据
    _Load() {
      getExpressCargoTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
        kor_id: this.p.kor_id,
        kcu_name: this.p.kcu_name,
        kec_express_id: this.p.kec_express_id,
        status: this.p.status,
        stime: this.p.time[0],
        etime: this.p.time[1],
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.p.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 150004) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开快件添加组件
    _OpenCreateExpressCargoModel() {
      this.$refs["nu-express-cargo"].open(0);
    },

    //打开快件修改组件
    _OpenUpdateExpressCargoModel(id) {
      this.$refs["nu-express-cargo"].open(id);
    },

    //打开客户选择模块
    _OpenCustomerSelectModel() {
      this.show_customer_select = true;
    },

    //复制单号列表
    copyExpressIdList() {
      const rows = this.$refs.table.selection;
      if (rows.length < 1) {
        return this.$utils.msg("请选择要复制的快件！");
      }
      console.log(rows);

      const kwh_name = rows[0].kwh_name;
      const wh_time = rows[0].add_wh_time;
      const kcu_name = rows[0].kcu_name;

      let text = "飞鱼国际物流-到件明细\r\n";
      text += "收件仓库：" + kwh_name + "\r\n";
      text += "清单时间：" + wh_time + "\r\n";
      text += "到件单号：\r\n";

      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        text += item.kcu_name + " " + item.express_id + "\r\n";
      }

      this.$utils.copyText(text);
      this.$utils.success("到件明细复制成功[" + kcu_name + "]!");
    },

    //删除
    _DelExpressCargo(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除快件【" +
          data.express_id +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delExpressCargo({ kec_id: data.kec_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
};
</script>
